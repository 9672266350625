import { Injectable } from '@angular/core';
import { BaseModel, BaseService } from '../auth/base.service';
import { MachineTask } from './machine-task.service';
import { Machine } from './machine.service';

import { Observable } from 'rxjs';


export interface TimedTask extends BaseModel<number>{
  loopCount : number;
  notifiedTimestamp : number;
  machine : Machine;
  repeat : number[];
  startTime : string;
  machineTask : MachineTask;

}
@Injectable({
  providedIn: 'root'
})

export class TimedTasksService extends BaseService<TimedTask, number> {

  protected path:string = `${this.config.endpoint}/hw/timedtask`

  public listAll(): Observable<TimedTask[]> {

    return this.http.get<TimedTask[]>(`${this.path}`);
  }
  //   public todayTasks(): Observable<TimedTask[]> {

  //   return this.http.get<TimedTask[]>(`${this.path}/hw/taskscheduler/tasksToday`);
  // }

  public scheduledNotExecuted(startTime : number,endTime : number ): Observable<MachineTask[]> {

    return this.http.get<MachineTask[]>(`${this.path}/notExecutedByIntervalo?startTime=${startTime}&endTime=${endTime}` );
  }
  public getScheduled(startTime : number,endTime : number ): Observable<MachineTask[]> {

    return this.http.get<MachineTask[]>(`${this.path}/timedTaskByInterval?startTime=${startTime}&endTime=${endTime}` );
  }
  public timedToday(startTime:number, endTime: number): Observable<MachineTask[]> {

    return this.http.get<MachineTask[]>(`${this.path}/timedToday?startTime=${startTime}&endTime=${endTime}` );
  }
}
